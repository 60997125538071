.body-container{
    position:relative;
    top:100vh;
    left:0px;
  
    width: 95% !important;
}
.our-clients,.body-container {
    width: 100% !important;
  
}
#Contact {
  overflow: hidden;
  h2 {
    color: #48494a;
  }
  .contact-location p {
    font-size: 18px;
    color: #48494a;
  }
  .findus-info li {
    color: #48494a;
    transition: all 0.2s;
  }
  .findus-info li a {
    text-decoration: none;
    color: #48494a;
    transition: all 0.2s;
    text-align: left!important;
  }
  .findus-info li, .findus-info{
    text-align: left!important;
  }
  .findus-info li i {
    transition: all 0.2s;
    margin-right: 10px;
  }
  .findus-info li:hover i,
  .findus-info li a:hover,
  .findus-info li:hover {
    color: var(--color-line);
  }
  .contact-socials i {
    font-size: 26px;
  }
  .contact-socials li {
    margin: 0 5px;
  }
  .contact-socials .facebook i {
    color: #1877f2;
  }
  .contact-socials .whatsapp i {
    color: #25d366;
  }
  .contact-socials .twitter i {
    color: #1da1f2;
  }
  .contact-socials .instagram i {
    color: #5851db;
  }
  .contact-form input,
  .contact-form textarea {
    padding: 20px;
    background-color: #f5f5f5;
  }
  .contact-form input::placeholder,
  .contact-form textarea::placeholder {
    color: #48494a !important;
  }
  .contact-form .form-control:focus {
    border-color: var(--color-line);
    box-shadow: 0 0 0 0.25rem rgb( 255 122 0 / 25%);
  }
  .contact-form button{
    background-color: #03267E;
    color: #fff;
    font-size: 18px;
    transition: all .2s;
    width: 100%;
  }
  .contact-form button:hover{
    background-color:  var(--color-line);
    border-color:  var(--color-line)    ;
  } 
}

.im {
  align-self: left;
  float: left;
}
.title {
  color: var(--color-text);
}

.none{
  background-color: rgb(0, 0, 77);
}
.button-slider{
  position: absolute;
  bottom: 4%;
  transform: translate(-50%, -50%);
  left:45%;
  z-index: 10;
  width: auto;
  animation-delay: 1s;
  
}
@media only screen and (max-width: 768px) {

  .button-slider{
   
    left:28%;
  }
}
.nav {
  position: absolute;
  top: 0px;
  right: 0px;
  transition: background-color 2s;
  z-index: 100;

  .nav-bar-items {
    position: absolute;
    top:0px;
    right:0px;
   transition:background-color 2s;
    z-index: 10;

    .nav-bar-items{
        position: absolute;
        top:20%;
        right:5%;
    }
   
    

}
.in-viewport{
    position: absolute;
  
   
}

.out-viewport{
    position: fixed;
    background-color: rgb(0, 0, 77);
}

  /* For mobile phones: */

  @media only screen and (max-width: 768px) {

    .button-slider{
   
      left:28%;
    }
    .nav-bar-items{
        display: none !important;
    }
    .out-viewport{
        .h1{
            font-size: 18px !important;
        }
    }
    

}


  /* For tablets phones: */

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
  

    .nav-bar-items{
        display: none !important;
    }
    .out-viewport{
        .h1{
            font-size: 35px !important;
        }
    }
           
    .button-slider{
   
      left:40%;
    }
  }
}
.in-viewport {
  position: absolute;
 
}

.out-viewport {
  position: fixed;
  background-color: rgb(0, 0, 77);
}

/* For mobile phones: */

@media only screen and (max-width: 768px) {
  .nav-bar-items {
    display: none !important;
  }
  .out-viewport {
    .h1 {
      font-size: 18px !important;
    }
  }
}

/* For tablets phones: */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .nav-bar-items {
    display: none !important;
  }
  .out-viewport {
    .h1 {
      font-size: 35px !important;
    }
  }
}

//This is for desktops

@media only screen and (min-width: 1024px) {
}

.container-slider {
    height: 100vh;
    color:white;
    position: absolute;
    z-index:1;
    top:0px;
    background-color: var(--color-theme);
    left:0px;
    justify-content: center;
   

    .blue-bg{
        height: 0%;
        width:20%;
        z-index: 1;
        background-color: var(--color-theme);
        position: absolute;
        top:0px;
        left: 0px;
       
        animation-delay:0s;
    
    }
    .slide-down{
        animation: slide-down 0.7s linear ;
    }
    
    @keyframes slide-down{
        0%{
            height: 0%;
        }
        50%{
            height: 100%;
        }
        55%{
            height: 100%;
        }
        100%{
            height: 0%;
        }
    }

    .bg-caption{
        width: 0%;
        z-index: 100;
        background-color:var(--color-line);  
        position: absolute;
        height: 10em;
        top:0px;
        left: 0px;
        animation: close-slide-double 0.5s linear ;
        animation-delay: 1.2s;

    }
    
    @keyframes close-slide-double{
        0%{
            width: 0%;
            
        }
        50%{
            width:100%;
            z-index: 0;
          
        }
        100%{
            width:0%;
            z-index: 0;
        
        }
    }
    .title-slider{
        animation-delay: 1s;
        h1{
            font-family: aloe!important;

        }
    }

    .img-logo{
        position: absolute;
        z-index:100;
        opacity: 0.8;
        top:10%;
        left: 35%;
        overflow: hidden;
        animation-delay: 0s;
        img{
            width:50%;
            height:auto;
          
            border-radius: 10px;
        }
        .log-bg-layer{
            width:100%;
            height:100%;
            position: absolute;
            top:0px;
            z-index: 100;
            background-color: var(--color-theme);
            opacity: 0;
        }
    }
    
.caption {
    color: white;
}

.img-home{
    height:100%;
   
    overflow: hidden;
    position: absolute;
   
    
    z-index: 0; //most impoprtant part, this set the priority
   
    
    
    img{
        height: auto;
        transform: scale(1);
        animation:zoom-pic 10s;
        animation-delay: 2s;
        opacity: 1;
    }
   
    
}
@keyframes zoom-pic{
    from{
        -ms-transform: scale(1); 
        -webkit-transform: scale(1); 
        transform: scale(1); 
        width:120%;
      
   
    }
    to{
        width:120%;
       -ms-transform: scale(1.5); /* IE 9 */
        -webkit-transform: scale(1.5); /* Safari 3-8 */
        transform: scale(1.5); 
       
    }
    
}

.description {
    position: relative;
    top:5%;   
    width:80%;
    margin:1px auto;
    animation-delay: 1s;
}


.thing{
    height: 100%;
    width: 100%;
}

.but{
    position:relative;
   
}
.line{
    width:65%;
    height: 2px;
    background-color: var(--color-line);
    animation: close-line 1s linear ;
}
@keyframes close-line {
    from{
        width: 0%;
    }
    to{
        width:70%;
        z-index: 0;

    }
}
.slider-body{
    position: relative;
    z-index: 100;
   height:60%;
    top:40%;
    left:0px;
}
.bg-slider {

    position: absolute;
    height: 100vh;
    width:100%;
    background-color: black;
    opacity: 0.5;
    
}

}




// apply this style in small phones 

  /* For mobile phones: */

@media only screen and (max-width: 768px) {
    .container-slider {

    .img-logo{
       width: 50%;
       left:25%;
        img{
            width:100%;
            height:auto;
          
            border-radius: 5px;
        }  
        }
        .img-home{
            img{ 
                    width: 420px!important;
            }
        }
            
  
    
.slider-body{
 
   height:40%;
    top:40%;
    left:0px;
}


}

.description {
    p {
        font-size: 1em;
    }   



    }

}


  /* For tablets phones: */

  @media only screen  and (min-width: 768px) and (max-width: 1024px) {
    .container-slider {
        .img-logo{
            width:20em;
            height:20em;
            }
 
    }
   
.img-home{
   
    img{
        
        width: 120%;
    }
}
    
.description {

    p {
        font-size: 1.5em;
    }
   
}

  }

//This is for desktops

@media only screen and (min-width: 1024px) {
  


    .img-logo{
       
        width:40em;
        height:40em;
   
}
.img-home{
   
    img{
        top:-65%;
        width: 100% !important;
    }
    @media only screen and (max-height: 650px) {
        img{
           // top:-75%;
         
        }

    }
}

.description {

    p {
        font-size: 2em;
    }
   
}
   

   
}
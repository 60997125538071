.whatsappIcon{
    bottom: 3%;
    left:1%;
    z-index:10;
   
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color:rgb(0, 128, 0);
    justify-content: center;
    padding: auto;
    position:relative;
    margin: auto;
    animation: glow-whatsappIcon 4s linear infinite;
   
}
.whatsappIcon a{  width: 50px;
    height: 50px;
   margin: auto;
   padding: auto;
    font-size:2.5em;
   
}
.red-dot{
    position:absolute;
    top:2px;
    right:1px;
    width:10px;
    background-color:red;
    height:10px;
    border-radius:50%;

}
.whatsappIcon a i{
    color:white;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top:50%;
}
@keyframes glow-whatsappIcon{
    0%{
        box-shadow: 0px 0px 0px 0px  rgb(0, 128, 0);
    }
    50%{
        box-shadow: 1px 1px 8px 3px  rgb(40, 131, 40);
    }
    100%{
        box-shadow: 0px 0px 0px 0px  rgb(0, 128, 0);
    }
}
.captions{
    box-shadow: 10px 20px 20px rgba(0,0,0,0.5) ;
    background-image: linear-gradient(to bottom right, black, var(--bg-color));
    padding: 2rem;
    border-radius: 10px;
    border: 2px dotted white;
    font-size: 1.2em;
    align-items: end;
    
    .text{
        color: white;
         
         p{
             color: white;
             font-size: 16px;
             @media only screen and (max-width: 768px) {
                font-size: 15px;
             }
             
         }
 
         .conect-button{
             border: 1px solid white;
             color: rgb(100, 100, 255);
             padding-left: 50px;
             right: 2%;
             bottom: 2%;
             position: absolute;
         }
     }
}
// .hover-scale{
//     transform: scale(1);
//     transition: all 1s;
// }

// .hover-scale:hover{
//     transform: scale(1.05);
    
// }

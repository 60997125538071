.scrollIcon{
    bottom: 3%;
    right:1%;
    z-index:10;
    //box-shadow: 1px 1px 10px 10px green;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color:#000;
    justify-content: center;
    padding: auto;
    margin: auto;
   
    a{  width: 50px;
        height: 50px;
       margin: auto;
       padding: auto;
        font-size:2em;
        i{
            color:white;
            width: 50px;
        height: 50px;
            text-align: center;
           
        }
    }

}

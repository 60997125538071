.button-container{
    width: -moz-fit-content;
    width: fit-content;
    
    i{
        margin:5px;
    }

    background-image:var(--button-theme);
    animation: glow 2s linear infinite;
    .button-theme{
        background-image: transparent;//var(--button-theme);
        border:1px solid white;
        color:white;
    }

    @keyframes glow{
        from{
            box-shadow: 0px 0px 0px 0px  #03267E;
        }
        to{
            box-shadow: 1px 1px 15px 4px  #03267E;
        }
    }

    
.bg-effect-button{
    width:0%;
    height: inherit;
    border-radius: 6px;
    transition: width 0.5s ;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
   background-color: white;
}


}



.button-container:hover{

    .button-theme{
        background-image: transparent;
        border:1px solid var(--color-theme);
        color:var(--color-line);
       z-index: 1;
    }
   

    .bg-effect-button{
        width:100%;
    }
}
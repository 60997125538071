
.menuBar{
    position: absolute;
    top:-10px;
    right:1%;
    font-size: 2rem;
    color:white;
}



.slideBar{
  width:80%;
  height:100vh;
  top:0;
  left: 0;
  margin: 0;
  background-color: var(--bg-color);
  .slide-bar-title{
    font-weight: bold;
    font-family: josifin;
    position: relative;
    left:5%;
    width: 94%;

  }
}

.sidebar-links {
  list-style: none;
  li{
    text-align: center;
    padding: 10px 0;
    border: 1px solid #fff;
    margin-bottom: 15px;
    border-radius: 5px;
    position: relative;
  }
  li a {
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    transition: all .2s;

    font-weight: bold;
    text-transform: uppercase;
  }
  li a:hover {
    color:var(--color-line);
    text-decoration: none;
  }
  li i{
    position: absolute;
    left: 10px;
    font-size: 20px;
    top: 13px;
  }
  margin: 0;
}
.sidebar-footer{
  i{
    margin-right: 10px;
  }
  li{
    font-size: 15px;

  }
}

  /* For tablets phones: */

  @media only screen and (min-width: 768px) and (max-width: 1024px) {

    .menuBar{
        font-size: 3rem;
    }
  
  }
  
//This is for desktops

@media only screen and (min-width: 1024px) {
  
    .menuBar{
        display: none;
    }
   
}

 

 @font-face {
    font-family: aloe;
    src: url(../../assets/fonts/Aloevera-OVoWO.ttf);
  }
  .font-family-aloe{
    font-family: aloe;
  }
 @font-face {
    font-family: josifin;
    src: url(../../assets/fonts/JosefinSans-Regular.ttf);
  }
  .font-family-josifin{
    font-family: josifin;
  }
  
 @font-face {
    font-family: playfair;
    src: url(../../assets/fonts/PlayfairDisplay-Regular.ttf);
  }
  .font-family-playfair{
    font-family: playfair;
  }
html{
    --color-theme: rgb(0, 0, 77);
    --bg-color:#00004d;
    --blue-color:#03267E;
    --bg-color-imge:linear-gradient(to right, #03267E,  rgb(0, 0, 77));
    --btn-border:5px;
    --button-theme:linear-gradient(to right, #03267E,  #000);
    --color-line:#FF7A00;
    --color-text: #fff;
    --image-border-radius:10px;
    --border-radius:8px;
    scroll-behavior:smooth;
    .bg-image-card:hover{
        background-image: var(--bg-color-imge);
    }
    
/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--bg-color);
  }
  
  /* Handle */
 ::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
    .scale{
        transform: scale(1);
    }
    .z-index-9{
        z-index: 9;
    }
    .hover-scale:hover{
        transform: scale(1.05);
    }
    //this will add 1s transition to the element 
    .transition-all{
        transition: all 1s;
    }
   .top-50{
    top: 50%;
   }
   .hw-100{
    height: 100vh;
   }
   .w-90{
    width:90%!important;
   }

    .underline {
        margin:1% auto;
        display:block;
        height:1px;
        width:50%;
        transition: width 1s;
        background-color:var(--color-line);
    }
    .underline:hover,h2:hover .underline,h1:hover .underline,h3:hover .underline,p:hover .underline{
        width:95%;
    }
    .z-index-10{
        z-index:10;
    }
    .z-index-100{
        z-index: 100;
    }
    .color-theme{
        color: var(--color-line);
    }
    .top-5{
        top:8%;
    }

@media only screen and (max-width: 768px) {
   
        --border-radius:4px;
        

    }

}
.top-50{
    top: 50%;
}
.border-radius{
    border-radius: var(--border-radius);
}
.opacity-5{
    opacity: 0.5;   
}

.opacity-7{
    opacity: 0.7;   
}
.top-40{
    top: 40%;
}
.left-0{
    left: 0px;
}

.right-0{
    right: 0px;
}
h1{
    font-size: 3em ;
}
h2{
    font-size: 2.5em ;
}
h3{
    font-size: 1.9em ;
}
h4{
    font-size: 1.3em ;
}
.read-more{
    color:var(--color-line);
    text-decoration: underline;
}
.line-slide{
    background-color: #ccc;
}
.hover:hover{
    opacity: 0.5;
}
.hover-zoom:hover{
    
transform: scale(1.2);

}
.z-index-1{
    z-index: 1;
}


// apply this style in small phones 

  /* For mobile phones: */

  @media only screen and (max-width: 768px) {
  
.hover-zoom:hover{
    
    transform: scale(1.1);
    
    }

h1{
        font-size: 3em;
   
    }
    
h2{
    font-size: 2em ;
}
h3{
    font-size: 1.2em ;
}

h4{
    font-size: 1em ;
}
.p-hide{
    display: none!important;
}
}


  /* For tablets phones: */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  

    h1{
        font-size: 3em;
    }
        
    h2{
        font-size: 2.4em ;
    }
    h3{
        font-size: 1.7em ;
    }
    
h4{
    font-size: 1.3em ;
}
 .d-hide{
    display: none!important;
 }
   
}
@media only screen and (min-width: 1024px) {
    .d-hide{ 
        display: none !important;
    }

}
